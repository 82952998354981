
<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line ">
      <div class="content-tabs content-tabs--separator"
           v-bind:class="{'loading-tabs': $store.getters.getRefillProductsLoading === true}">

        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--              <span class="admin-edit" @click="editTranslate([item.label])"></span>-->
              <template>
                {{item.label}}
              </template>
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">
        <div class="btn-left-block-i"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_selectAll'])"></span>
          <DefaultCheckbox
              :label="$t('common_selectAll.localization_value.value')"
              @input="(val) => $emit('selectAll', val)"
          />
        </div>
      </div>
<!--      <div class="btn-right-block">-->
<!--        <div-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"-->
<!--        >-->
<!--          <div class="admin-edit" @click="editTranslate(['common_resetSelected'])"></div>-->
<!--          <MainButton class="btn-fit-content btn-right-block-i"-->
<!--                      :value="$t('common_resetSelected.localization_value.value')"-->
<!--                      :ico="'ico'"-->
<!--                      @click.native="$emit('resetSelected')"-->
<!--          ></MainButton>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  // import MainButton from "@/components/UI/buttons/MainButton/MainButton";


  export default {
    name: "RefillProductsHead",
    components: {
      // MainButton,
      DefaultCheckbox,
      ToggleFilterButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    watch: {

    },

    created() {

    },

    data() {
      return {
        isModalImportPopup: false,
      }
    },



    methods: {


      changeTab(val) {
        this.$emit('changeTab', val)
      },

      selectAll(val) {
        this.$emit('selectAll', val)
      },

      showSearchEngineBlock(){
        this.isShowSearchEngineBlock = !this.isShowSearchEngineBlock;
      },

      changeImportPopup(val){
        this.isModalImportPopup = val
      },
    }
  }
</script>


<style>

  @media (max-width: 992px) {
    .btn-right-block .content-top-line-wrap{
      top: 0;
    }
    .table-head-btn__btn.btn-style{
      font-size: 0;
    }
  }
</style>
