<template>
  <div class="fragment">
    <template v-if="$store.getters.getRefillProducts.length > 0 && !$store.getters.getRefillProductsLoading">
      <div class="site-table-wrap small-table products-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'refillProducts_product',
          'refillProducts_binWhereEnded',
          'refillProducts_binWhereHas',
          'common_manage',
        ])"></span>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{$t('refillProducts_product.localization_value.value')}}</th>
            <th>{{$t('refillProducts_binWhereEnded.localization_value.value')}}</th>
            <th>{{$t('refillProducts_binWhereHas.localization_value.value')}}</th>
            <th width="40%;"></th>
            <th width="175px;">{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getRefillProducts"
              :key="index">
            <td>
              <DefaultCheckbox
                  class="empty-label"
                  :selectedNow="selectedNow"
                  :dataValue="item.id"
              />
            </td>
            <td>
              <div style="max-width: 400px; width: fit-content;" class="word-break white-space-normal">{{item.product_full_name}}</div>
            </td>
            <td>
              {{item.cell_combo_name}}
            </td>
            <td>
                <div :key="cellIndex" v-for="(cellItem, cellIndex) in item.available_products">{{cellItem}}
                  <template v-if="item.available_products.length !== cellIndex + 1">,</template></div>
            </td>
            <td></td>
            <td>
              <div class="table-right table-manage-list table-manage-list--small">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                        :value="$t('product_SendTo.localization_value.value')"
                        :type="'sendTo'"
                        @click.native="moveProductCell = true; moveProduct = item.product; moveCellId = item.cell.id"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getRefillProducts"
                 :key="index"
            >
              <RefillProductsTableMobile
                      :item="item"
                      :getStorageId="getStorageId"
                      @moveProductCell="moveProductCell = true; moveProduct = item; moveCellId = item.cell.id"
                      @changeInfoIDPopup="changeInfoIDPopup(true, item.product.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getRefillProductsCommonList.next_page_url !== null && $store.getters.getRefillProducts.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextRefillProductsPage}"
                    :count="$store.getters.getRefillProductsCommonList.total - $store.getters.getRefillProductsForPage < $store.getters.getRefillProductsForPage ?
                  $store.getters.getRefillProductsCommonList.total - $store.getters.getRefillProductsForPage:
                  $store.getters.getRefillProductsForPage"
            />
<!--            <ExportBtn-->
<!--                @downloadFiles="type => $emit('downloadFiles', type)"-->
<!--                    class="table-bottom-btn__right"/>-->
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getRefillProductsLoading === false && $store.getters.getRefillProducts.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <InfoIDPopup
        v-if="isModalInfoIDPopup"
        @close="changeInfoIDPopup(false)"
    />

    <MoveProductCell
        v-if="moveProductCell"
        :product="moveProduct"
        :moveCellId="moveCellId"
        :navTabs="navTabs"
        @close="moveProductCell = false"
        @moveSuccess="moveProductCell = false; $emit('reload')"
    />

  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  // import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import InfoIDPopup from "../../../../popups/InfoIDPopup/InfoIDPopup";
  import RefillProductsTableMobile from "../RefillProductsTable/RefillProductsTableMobile/RefillProductsTableMobile";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import MoveProductCell from "@/components/modules/RefillProductsModule/chunks/MoveProductCell/MoveProductCell";
  import {productMixins} from "@/mixins/productMixins/productMixins";
  import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


  export default {
    name: "RefillProductsTable",

    components: {
      DefaultCheckbox,
      // CountCircle,
      // TooltipTextHelper,
      MoveProductCell,
      NoResult,
      RefillProductsTableMobile,
      ShowMore,
      // ExportBtn,
      LinkButton,
      ManagerButton,
      InfoIDPopup,
    },

    props: {
      sortParams: Object,
      selectedNow: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    mixins: [
      mixinDetictingMobile,
      productMixins,
    ],

    computed: {
      getStorageId() {
        return this._.find(this.navTabs, {active: true})?.id
      },
    },

    // mounted() {
    //   this.isMobile = this.isMobileFunc
    //
    //   window.addEventListener('resize', () => {
    //     this.isMobile = this.isMobileFunc
    //   })
    // },

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalRefillProductsToWarehouse: false,

        show1: false,
        show2: false,

        isModalCommentsPopup: '',
        isModalSendEmailPopup: '',
        isModalLastFiveIPPopup: '',
        isModalStatisticPopup: '',
        isModalInfoIDPopup: '',

        moveProductCell: false,
        moveProduct: null,
        moveCellId: -1,

      }
    },

    methods: {
      closePopup(){
        // this.isModalRefillProductsToWarehouse = false
      },

      changeInfoIDPopup(val, id = false){
        let storage = this.getStorageId
        if(id) {
          this.$store.dispatch('getRefillProducts', id + `?storageId=${storage}`).then(() => {
            this.isModalInfoIDPopup = val
          })
          return
        }

        this.isModalInfoIDPopup = val
      },

      getItemsWithCells(storage) {
        let array = []
        storage.map(item => {
          if(item.cell)
            array.push(item)
        })
        return array
      },
    }

  }
</script>

<style lang="scss" scoped>

  .site-table thead th,
  .site-table tbody td{
    padding-right: 5px;
    padding-left: 5px;
  }

  .products-table .table-manage-list .manager-btn{
    width: 165px;
  }

  .products-table .ellipsis{
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }

  @media(max-width: 1600px){
    .hide-1600{
      display: none;
    }
  }

</style>
